import { FormattedMessage } from 'react-intl';
import background from '../../assets/bannerImg.png';
import React, { useState } from 'react';
import css from './heroContainer.module.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { PrimaryButton } from '../Button/Button';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import Modal from '../Modal/Modal';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { connect } from 'react-redux';
const HeroContainer = props => {
  const { history, className, onManageDisableScrolling } = props;
  const classes = className;
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const videoModalToggleButton = () => {
    setIsVideoModalOpen(p => !p);
  };

  return (
    <>
      <div className={css.SectionHero}>
        <div className={css.backgroundImg}>
          <img src={background} alt="" />
        </div>
        {/* <div className={css.HeroContentMain}> */}
        <div className={css.heroComponent}>
          <p className={css.herotext}>
            Largest Online Marketplace for Pacific Islanders
          </p>
          <p className={css.secondText}>
            {' '}
            Connecting Pacific Islanders around the world. The most safe and
            reliable platform to buy and sell your products and services.
          </p>
          {/* <div> */}
          <PrimaryButton
            className={css.browseBtn}
            onClick={() => videoModalToggleButton()}
          >
            Watch Video
          </PrimaryButton>

          {/* </div> */}
        </div>
        {/* </div> */}
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={isVideoModalOpen}
        onClose={videoModalToggleButton}
        // usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {' '}
        {isVideoModalOpen && (
          <iframe
            // width="560"
            // height="315"
            src="https://www.youtube.com/embed/TBirWCCXwPY?si=PuQ-PqLh0Io4jz6C"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(HeroContainer);
